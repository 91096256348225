<template>
  <div class="col m-6">
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col d-flex align-items-center justify-content-center mt-5">
          <div class="alert alert-danger mt-4" role="alert">網站服務調整 (2025/3/30起) <a href="https://www.facebook.com/just.bk.me/posts/967596972205081" target="_blank">見詳細公告</a></div>
        </div>
        <h4>
          <!-- <p class="mt-5">台灣虎航 24夏季第一波開賣各航點價格一覽表<small> (逐步更新，非完整)</small></p> -->
          <p class="mt-0">{{ main_title }}<small> (逐步更新，非完整)</small></p>
        </h4>
        <small class="mt-0">
          <!-- <h2><p>台灣虎航 24夏季第一波開賣各航點價格一覽表</p></h2> -->
          <ul>
            <li>若登入後仍查無資料，代表本站今日尚未進行資料更新，<span class="fs-4 text-danger">票價資料更新原則：不定期、出發日近的先</span></li>
            <li>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器</li>
            <!-- <li v-if="help_title !== ''">
              <span class="fs-6 text-danger">{{ help_title }}</span
              >，若沒有可以點擊一旁 <router-link to="/NewUser" class="btn btn-outline-primary"> 註冊 </router-link>
            </li> -->
            <li>若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗</li>
            <li>{{ help_title }}況</li>
            <li>僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)</li>
            <li>{{ sub_title }}</li>
          </ul>
        </small>
        <template>
          <div class="ad-container">
            <Adsense data-ad-client="ca-pub-2965351057490665" data-ad-slot="5295990485" data-ad-format="auto" data-full-width-responsive="true"> </Adsense>
          </div>
        </template>
        <vue-good-table
          :line-numbers="false"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            perPage: 10,
            enabled: true,
            mode: 'pages',
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              // { field: 'departureDate', type: 'asc' },
              { field: 'fareAmount', type: 'asc' },
            ],
          }"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Cookies from "js-cookie"
  import GlobalVar from "@/mixins/global_var.js"
  import moment from "moment-timezone"
  export default {
    name: "VueLowFare",
    // components: { Table },
    mounted() {
      // if (Cookies.get("login_token")) {
      const login_token = Cookies.get("login_token")
      this.checkLoginTokeVaild(login_token)

      let redirect_url = "/Login"

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const airline = ref.split("-")[0]
        const tag = ref.split("-")[1]
        redirect_url = `/Login?url=/LowFare?ref=${airline}-${tag}`
      }

      // this.flag = sessionStorage.getItem("ref")
      // if (!sessionStorage.getItem("is_email_confirm")) {
      // if (sessionStorage.getItem("is_email_confirm")) {
      this.columns.splice(4, 0, {
        label: "張數",
        width: "100px",
        field: "fare1Available",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
          filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })

      this.columns.splice(1, 0, {
        label: "航空業者",
        width: "100px",
        field: "airline",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          filterDropdownItems: ["MM", "SL", "VZ", "TR", "D7", "FD", "AK", "IT"], // dropdown (with selected values) instead of text input
          // filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })
      // }
      // }
      // }
      let airline = null,
        tag = null

      this.main_title = "載入中..."
      this.sub_title = "載入中..."
      this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況"

      if (sessionStorage) {
        sessionStorage.setItem("page", "LowFare")
        sessionStorage.setItem("ref", false)
        sessionStorage.removeItem("airline", airline)
        sessionStorage.removeItem("tag", tag)
      }

      if (Cookies) {
        Cookies.set("page", "LowFare", { secure: true })
        Cookies.set("ref", false, { secure: true })
        Cookies.remove("airline")
        Cookies.remove("tag")
      }

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        airline = ref.split("-")[0]
        tag = ref.split("-")[1]

        if (airline === "IT" && tag === "S25Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 25夏季班表第 4 波開賣"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
          // this.help_title = "所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 2 位」的情況"
          // this.$set(this.columns[0], "hidden", true)
          // this.$set(this.columns[1], "hidden", true)
          // this.$set(this.columns[5], "hidden", true)
        }

        if (airline === "TEST" && tag === "TT") {
          this.main_title = "測試用"
          this.sub_title = ""

          if (typeof sessionStorage !== "undefined") {
            alert("ok")
          } else {
            alert("not ok")
          }
        }

        // this.help_title = "如持有本站會員帳號，可於登入後查看該優惠價格所持有的張數"
        if (sessionStorage) {
          sessionStorage.setItem("ref", true)
          sessionStorage.setItem("airline", airline)
          sessionStorage.setItem("tag", tag)
        }
        if (Cookies) {
          Cookies.set("ref", true, { secure: true })
          Cookies.set("airline", airline, { secure: true })
          Cookies.set("tag", tag, { secure: true })
        }
      }

      if (!sessionStorage.getItem("is_email_confirm") && airline != null && tag != null) {
        this.$alert("從 2024/11/8 起一般會員（含驗證會員）於每月可查詢懶人包件數為 5 次，付費會員（或贊助會員）則不受限", "系統訊息", "error").then(() => {
          window.location.href = `${redirect_url}`
        })
      } else {
        const ref = this.$route.query.ref
        this.getLowfareInfo(ref)
        this.initLowFare(airline, tag)
      }
    },
    mixins: [GlobalVar],
    data: function () {
      return {
        flag: null,
        main_title: "",
        sub_title: "",
        help_title: "",
        isLoading: false,
        columns: [
          {
            label: "前往官網",
            width: "150px",
            field: "goLink",
            html: true,
          },
          {
            label: "出發日",
            width: "150px",
            field: "departureDate",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "yyyy-MM-dd",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "星期",
            field: "departureDateWeeKFormat",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "班次",
            field: "flightNo",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "currency",
          //   field: "currency",
          // },
          {
            label: "價格",
            width: "130px",
            field: "fareAmount",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "出發地",
            width: "150px",
            field: "departureStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "目的地",
            width: "150px",
            field: "arrivalStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "資料時間",
            width: "150px",
            field: "fetchCreatedAt",
          },
        ],
        rows: [],
      }
    },
    methods: {
      searchJson: function (data, searchCriteria) {
        return data.filter((item) => {
          return Object.keys(searchCriteria).every((key) => {
            // 如果 key 是 content 裡的屬性
            if (key.startsWith("content.")) {
              const contentKey = key.split(".")[1]
              if (Array.isArray(item.content[contentKey])) {
                return searchCriteria[key].every((val) => item.content[contentKey].includes(val))
              }
              return item.content[contentKey] === searchCriteria[key]
            }

            // 處理日期範圍搜尋
            if (key === "open_d" || key === "close_d") {
              const searchDate = new Date(searchCriteria[key])
              const itemDate = new Date(item[key])
              return searchDate.getTime() <= itemDate.getTime()
            }

            // 一般字串搜尋（包含部分匹配）
            if (typeof item[key] === "string") {
              return item[key].toLowerCase().includes(searchCriteria[key].toLowerCase())
            }

            // 精確匹配
            return item[key] === searchCriteria[key]
          })
        })
      },
      async getLowfareInfo(index) {
        const me = this

        try {
          const response = await axios.get("./lowfare.json")
          let jsonData = response.data

          const result = me.searchJson(jsonData, {
            ref: index,
          })

          if (result.length === 0) {
            me.$alert(`參數讀取錯誤1`).then(() => {
              window.location.href = `./`
            })
          } else {
            me.main_title = result[0].mainTitle
            me.sub_title = result[0].subTitle
            me.$set(me.columns[1], "filterOptions", {
              enabled: true,
              filterDropdownItems: result[0].content["airline"],
            })

            const open_d = result[0].open_d
            const close_d = result[0].close_d
            if (!moment().tz("Asia/Taipei").isBetween(open_d, close_d)) {
              me.$alert(`活動尚未開始或者已經結束`).then(() => {
                window.location.href = `./`
              })
            }
          }
        } catch (error) {
          me.$alert(`參數讀取錯誤2`).then(() => {
            window.location.href = `./`
          })
          console.error("Error loading JSON data:", error)
        }
      },
      initLowFare: async function (airline, tag) {
        // initLowFare(airline, tag) {
        const me = this
        if (airline == null && tag == null) {
          // axios.get(`${me.$apiUrl}lowfare`).then((response) => {
          //   me.rows = response.data
          // })
          // this.$set(this.columns[0], "hidden", true)
          // this.$set(this.columns[1], "hidden", true)
          // this.$set(this.columns[5], "hidden", true)
          window.location.href = `/`
        } else {
          let user_uuid = null

          if (sessionStorage) {
            if (sessionStorage.getItem("uuid")) {
              user_uuid = sessionStorage.getItem("uuid")
            }
          }

          if (Cookies) {
            if (Cookies.get("uuid")) {
              user_uuid = Cookies.get("uuid")
            }
          }

          const json_d = {
            airline: airline,
            tag: tag,
            uuid: user_uuid,
          }

          let usage = null
          let flag = false
          await axios({
            method: "POST",
            url: `${me.$apiUrl}querylowfare_usage2`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(async function (response) {
            // usage = response.data
            if (response.data.length === 0) {
              flag = true
            } else {
              usage = response.data[0]
              // console.log("usage", response.data)

              if (sessionStorage.getItem("role_type") === "Sponsored") {
                flag = true
              } else {
                if (usage.count < 5 && usage.count >= 0) {
                  flag = true
                } else {
                  flag = false
                }
              }
            }

            if (flag) {
              await axios({
                method: "POST",
                url: `${me.$apiUrl}lowfare`,
                data: json_d,
                headers: {
                  Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  "Content-Type": "application/json",
                },
              }).then(function (response) {
                me.rows = response.data
              })
            } else {
              me.$alert("超過每月可查詢件數額度", "系統訊息", "error")
            }
          })
        }
      },
      columnFilterFn: function (data, filterString) {
        const x = parseInt(filterString)
        return data == x
        // return x
        // return data >= x - 5 && data <= x + 5
      },
    },
  }
</script>

<style></style>
